import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const facilityMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;
  {
    subheader: "general",
    items: [
      {
        title: "Transit Plan Listing",
        path: PATH_DASHBOARD.general.transitPlan,
        icon: ICONS.grid,
      },
      {
        title: "QC Form",
        path: PATH_DASHBOARD.general.qcForm,
        icon: ICONS.grid,
      },
    ],
  },
];

export { facilityMenu };
