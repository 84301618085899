import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const webMasterMenu = [
  {
    subheader: "general",
    items: [
      {
        title: "Manage Admin",
        icon: ICONS.user,

        children: [
          {
            title: "Manage Restaurants",
            path: PATH_DASHBOARD.general.manageRestaurants,
          },
          {
            title: "Manage Platform",
            path: PATH_DASHBOARD.general.managePlatforms,
          },
          {
            title: "Manage User Types",
            path: PATH_DASHBOARD.general.manageUserTypes,
          },
          {
            title: "Manage Users",
            path: PATH_DASHBOARD.general.manageUsers,
          },
        ],
      },
    ],
  },
];

export { webMasterMenu };
