import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import WidgetSummary from "../../components/data-grid/WidgetSummary";
import { GetGridata } from "src/service/api";
import { truncate } from "lodash";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import { Container } from "@mui/system";

function MetricsCards({ analysis, page }) {
  const [data, setData] = useState(null);
  const [icon, setIcon] = useState("carbon:delivery-parcel");

  useEffect(() => {
    console.log({ analysis: analysis });
    setData(analysis);
  }, [analysis]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        {data !== null && (
          <Grid style={{ margin: "auto" }} item xs={12} sm={6} md={2}>
            <AnalyticsWidgetSummary
              title="Total Orders Served"
              total={data.totalOrders}
              icon={icon}
            ></AnalyticsWidgetSummary>
          </Grid>
        )}
        {data !== null && (
          <Grid style={{ margin: "auto" }} item xs={12} sm={6} md={2}>
            <AnalyticsWidgetSummary
              title="Total Containers Sent"
              total={data.totalContainersSent}
              icon={"bi:box-fill"}
            ></AnalyticsWidgetSummary>
          </Grid>
        )}
        {data !== null && (
          <Grid style={{ margin: "auto" }} item xs={12} sm={6} md={3}>
            <AnalyticsWidgetSummary
              title="Containers Collected Back"
              total={data.totalContainersReceived}
              subText={"% of Containers Collected"}
              subTitle={data.percentageOfContainersCollected}
              icon={"akar-icons:check-box-fill"}
            ></AnalyticsWidgetSummary>
          </Grid>
        )}

        {data !== null && (
          <Grid style={{ margin: "auto" }} item xs={12} sm={6} md={2}>
            <AnalyticsWidgetSummary
              title="Average Container Per Order"
              total={data.averageContainersReceivedPerOrder}
              icon={"icon-park-outline:delivery"}
            ></AnalyticsWidgetSummary>
          </Grid>
        )}

        {data !== null && (
          <Grid style={{ margin: "auto" }} item xs={12} sm={6} md={2}>
            <AnalyticsWidgetSummary
              title="Median time for Pickup (Hours)"
              total={data.median}
              icon={"ant-design:field-time-outlined"}
            ></AnalyticsWidgetSummary>
          </Grid>
        )}
      </div>
    </Container>
  );
}

export default MetricsCards;
