import useSettings from "../../hooks/useSettings";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import _ from "lodash";
import DatePicker from "@mui/lab/DatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableSortLabel,
  TablePagination,
  TextField,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  IconButton,
  OutlinedInput,
  Typography,
  CircularProgress,
} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { useEffect, useState } from "react";
import "../../styles/dataGrid.css";
import Iconify from "../../components/Iconify";
import { GetGridata } from "../../service/api";
import { visuallyHidden } from "@mui/utils";
import { truncate } from "lodash";
import { CSVLink } from "react-csv";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Label from "src/components/Label";
import { useTheme } from "@mui/material/styles";

export default function OrderListing() {
  const { themeStretch } = useSettings();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [filter, setFilter] = useState("");
  const [filterName, setFilterName] = useState("");
  const [sort, setSort] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [timer, setTimer] = useState("");
  // console.log(_invoices)
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  // const prevColumn = usePrevious(column)

  let TABLE_HEAD = [];
  const onSort = (el) => {
    if (el !== "") {
      let isAsc = false;
      if (sort === "asc") {
        isAsc = true;
      }
      setSort(isAsc ? "desc" : "asc");
      setSortField(el);
    }
  };
  let FILTER_LIST = [];

  const getWeekly = () => {
    setFromDate(moment().subtract(7, "d").format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  };

  const getDaily = () => {
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
  };

  const handleColumnChange = async (event) => {
    // console.log(event)
    const value = event.target.value;
    // const {
    //     target: { value },
    // } = event;
    // console.log({"value":event.target.value })

    // // setColumn(
    // //     typeof value === 'string' ? value.split(',') : value,
    // console.log({ prev: sortOrder });
    // console.log({ vaal: value });
    // // );
    const sorter = (a, b) => {
      return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    };
    value.sort(sorter);

    // console.log({ vaal: value });
    // console.log({ "arr": arr })

    // console.log(arr)
    setColumn(value);
    // console.log({ col: column });
  };

  const onChangePage = (event, newPage) => {
    setPageNumber((initial) => initial + 1);
  };

  const onChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const date =
          toDate.length > 0 && fromDate.length > 0
            ? `${fromDate}:${toDate}`
            : "";
        console.log({ date: date });
        let res = await GetGridata(
          filter,
          filterName,
          pageNumber,
          pageSize,
          sortField,
          sort,
          date,
          "orderListing"
        );
        setData(res.rows);
        setTotal(res.total);
        setSortOrder(Object.keys(res.rows[0]));
        setColumn(Object.keys(res.rows[0]));
        FILTER_LIST = Object.keys(res.rows[0]).map((key) => {
          return {
            label: key,
            key: key,
          };
        });
        setLoading(false);
        console.log({ FILTER_LIST: FILTER_LIST });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetch();
    setTimer(moment().format("HH:MM"));
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    console.log({ filterName: filterName });
  };
  const loadData = async () => {
    setLoading(true);
    try {
      const date =
        toDate.length > 0 && fromDate.length > 0 ? `${fromDate}:${toDate}` : "";
      // console.log({ date: date });
      let res = await GetGridata(
        filter,
        filterName,
        pageNumber,
        pageSize,
        sortField,
        sort,
        date,
        "orderListing"
      );
      setData(res.rows);
      setTotal(res.total);
      setSortOrder(Object.keys(res.rows[0]));
      setColumn(Object.keys(res.rows[0]));
      setLoading(false);
      console.log({ res: res.rows });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    loadData();
    setTimer(moment().format("HH:MM"));
  }, [pageNumber, pageSize, sortField, sort]);

  return (
    <Paper className="main-div">
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <div>{`Last synced at: ${timer}`}</div>
      </div>
      <Paper elevation={10} className="search-bar">
        <div className="filterline1">
          <LocalizationProvider
            className="from-date"
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              label="From Date"
              inputFormat="dd/MM/yyyy"
              value={fromDate}
              onChange={(newValue) => {
                const date = moment(newValue).format("YYYY-MM-DD");

                setFromDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Divider
            className="divider"
            style={{ margin: "0rem 0.5rem" }}
            orientation="vertical"
            flexItem
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="To Date"
              inputFormat="dd/MM/yyyy"
              value={toDate}
              onChange={(newValue) => {
                const date = moment(newValue).format("YYYY-MM-DD");

                setToDate(date);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Divider
            className="divider"
            style={{ margin: "0rem 0.5rem" }}
            orientation="vertical"
            flexItem
          />
          <div className="filter-bar">
            <FormControl>
              <InputLabel
                style={{ margin: "0rem 0.5rem" }}
                id="demo-simple-select-label"
              >
                Filter
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                style={{
                  // margin: "0rem 0.5rem 0rem 0.5rem",
                  width: "10rem",
                }}
                disabled={data === null || data.length === 0}
                value={filter === null || filter.length === 0 ? "" : filter}
                onChange={(e) => setFilter(e.target.value)}
                label="Filter"
              >
                {data !== null &&
                  data.length &&
                  Object.keys(data[0]).map((el, idx) => (
                    <MenuItem key={idx} value={el}>
                      {el}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Divider
            className="divider"
            style={{ margin: "0rem 1rem" }}
            orientation="vertical"
            flexItem
          />

          <div style={{ width: "15rem" }}>
            <TextField
              fullWidth
              disabled={filter === null || filter.length === 0}
              value={filterName && filterName.length ? filterName : ""}
              onChange={(event) =>
                handleFilterName(event.target.value.toString())
              }
              placeholder="Search"
            />
          </div>

          <Divider
            className="divider"
            style={{ margin: "0rem 0.5rem" }}
            orientation="vertical"
            flexItem
          />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-checkbox-label">Column</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={column}
              onChange={handleColumnChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {data !== null &&
                data.length !== 0 &&
                Object.keys(data[0]).map((name) => {
                  // console.log({ "inex": column.indexOf(name) })
                  return (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={column.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Divider
            className="divider"
            style={{ margin: "0rem 0.5rem" }}
            orientation="vertical"
            flexItem
          />

          <CSVLink data={data}>
            <IconButton color="primary">
              <Iconify icon={"eva:download-outline"} />
            </IconButton>
          </CSVLink>
          <Divider
            style={{ margin: "0rem 0.5rem" }}
            orientation="vertical"
            flexItem
          />

          <div style={{ flex: 1, flexDirection: "column" }}>
            <Button
              style={{ height: "3rem", width: "7rem", borderRadius: "20rem" }}
              onClick={loadData}
            >
              <SearchIcon style={{ height: "3rem", width: "3rem" }} />
            </Button>
            <Typography style={{ fontSize: 11 }}>
              Click here to search
            </Typography>
          </div>
        </div>
        {/* <div style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
          <Button
            style={{
              margin: "0rem 1rem",
              height: "3rem",
              width: "15rem",
              color: "#005400",
              fontSize: "1rem",
            }}
            onClick={getWeekly}
            variant="contained"
          >
            Weekly Data
          </Button>
          <Button
            onClick={getDaily}
            style={{
              height: "3rem",
              width: "15rem",
              color: "#005400",
              fontSize: "1rem",
            }}
            variant="contained"
          >
            Daily Data
          </Button>
        </div> */}
      </Paper>
      {!loading ? (
        <Box
          sx={{
            width: "100%",
            margin: "1vh auto",
          }}
        >
          <TableContainer sx={{ position: "relative" }}>
            {data !== null && data.length !== 0 && (
              <Table
                sx={{
                  width: "100rem",
                  margin: "1%",
                  borderRadius: "20rem",
                }}
              >
                {/* <TableHeadCustom
                          order={sort}
                          orderBy={filterName}
                          headLabel={TABLE_HEAD}
                          rowCount={data.length}
                          numSelected={selected.length}
                          onSort={onSort}
                          onSelectAllRows={(checked) =>
                              onSelectAllRows(
                                  checked,
                                  data.map((row) => row.order_id)
                              )
                          }
                      /> */}
                <TableHead>
                  <TableRow>
                    {column.map((el, idx) => (
                      <TableCell
                        style={{
                          fontWeight: 600,
                          fontSize: "1rem",
                          backgroundColor: "#f4eeee",
                        }}
                        align="center"
                        key={idx}
                      >
                        <TableSortLabel
                          active={sortField === el}
                          direction={sortField === el ? sort : "asc"}
                          onClick={() => onSort(el)}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {el !== null
                            ? _.camelCase(el).replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                            : "-"}
                        </TableSortLabel>
                        {sortField === el ? (
                          <Box component="span" sx={visuallyHidden}>
                            {sort === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      hover
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {Object.entries(row).map((el, idx) => {
                        if (column.find((element) => element === el[0])) {
                          if (el[0] === "order_status") {
                            return (
                              <TableCell align="center">
                                <Label
                                  variant={
                                    theme.palette.mode === "light"
                                      ? "ghost"
                                      : "filled"
                                  }
                                  color={
                                    (el[1] === "With Collection Agent" &&
                                      "primary") ||
                                    (el[1] === "Available" && "info") ||
                                    (el[1] === "Finalized" && "success") ||
                                    (el[1] === "With Customer" && "success") ||
                                    (el[1] === "In Progress" && "warning") ||
                                    (el[1] === "Cancelled" && "error") ||
                                    (el[1] === "Top 4" && "warning") ||
                                    "error"
                                  }
                                >
                                  {el[1]}
                                </Label>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "500",
                                }}
                                align="center"
                                key={idx}
                                size="medium"
                              >
                                {truncate(el[1], { length: 40 })}
                              </TableCell>
                            );
                          }
                        }
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={60}
            size={50}
            thickness={4}
            sx={{ position: "absolute", top: "55%", left: "60%" }}
            disableShrink
          />
        </Box>
      )}

      {data !== null && data.length !== 0 && (
        <Box sx={{ position: "relative" }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total}
            rowsPerPage={pageSize}
            page={pageNumber - 1}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
      )}
    </Paper>
  );
}
