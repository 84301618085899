import { Box, Card, Container, Stack, Typography } from "@mui/material";
// @mui
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Image from "../../components/Image";
// components
import Page from "../../components/Page";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
// sections
import { LoginForm } from "../../sections/auth/login";
import LightLogo from "../../assets/Infinity_log_white.png";
import DarkLogo from "../../assets/Infinity_logo_black.png";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
  padding: theme.spacing(0, 2),

  elevation: 10,
  boxShadow: "5px 5px 10px rgb( 0 0 0 /0.2)",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const theme = useTheme();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const [isAdmin, setIsAdmin] = useState(false);

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            {theme.palette.mode === "light" && (
              <img alt="login" src={DarkLogo} />
            )}
            {theme.palette.mode === "dark" && (
              <img alt="login" src={LightLogo} />
            )}

            {/* <Image
              visibleByDefault
              disabledEffect
              alt="login"
              src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/Infinity+Logo+white.png"
            /> */}
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Sign in to Dashboard
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Username and Password.
                </Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
