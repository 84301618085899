import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const qcMenu = [
  {
    subheader: "general",
    items: [
      {
        title: "QC Form",
        path: PATH_DASHBOARD.general.qcForm,
        icon: ICONS.grid,
      },
    ],
  },
];

export { qcMenu };
