import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const adminMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;

  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "Inventory",
        path: PATH_DASHBOARD.general.inventory,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "Dashboard",
            path: PATH_DASHBOARD.general.inventory,
            icon: ICONS.alerts,
          },
          {
            title: "Live Inventory Restaurant",
            path: PATH_DASHBOARD.general.alerts,
            icon: ICONS.alerts,
          },
          {
            title: "Average Inventory Usage",
            path: PATH_DASHBOARD.general.eodReport,
            icon: ICONS.eodReport,
          },
          {
            title: "ledger",
            path: PATH_DASHBOARD.general.ledger,
            icon: ICONS.ledger,
          },
          {
            title: "overview",
            path: PATH_DASHBOARD.general.overview,
            icon: ICONS.overview,
          },
          {
            title: "metrics",
            path: PATH_DASHBOARD.general.metrics,
            icon: ICONS.metrics,
          },
          {
            title: "history",
            path: PATH_DASHBOARD.general.history,
            icon: ICONS.history,
          },
          {
            title: "insights",
            path: PATH_DASHBOARD.general.insights,
            icon: ICONS.insights,
          },
        ],
      },
      {
        title: "Reports",
        icon: ICONS.analytics,
        children: [
          {
            title: "Collection Sheet from DB",
            path: PATH_DASHBOARD.general.collectionSheet,
            icon: ICONS.grid,
          },
          {
            title: "Container Count List",
            path: PATH_DASHBOARD.general.continerCountList,
            icon: ICONS.grid,
          },
          {
            title: "Collection Agent Report",
            path: PATH_DASHBOARD.general.collectionReport,
          },
          {
            title: "Opt-In Orders",
            path: PATH_DASHBOARD.general.OptinOrderList,
          },

          {
            title: "Opted for Bag Report",
            path: PATH_DASHBOARD.general.optedForBag,
          },
          {
            title: "Dispatch Plan Report",
            path: PATH_DASHBOARD.general.dispatchPlan,
          },
          {
            title: "Restaurant History Report",
            path: PATH_DASHBOARD.general.restaurantStatusHistory,
          },
          {
            title: "Order Report",
            path: PATH_DASHBOARD.general.notScheduled,
            icon: ICONS.grid,
          },
          {
            title: "Packaging Charges Report",
            path: PATH_DASHBOARD.general.packagingReport,
            icon: ICONS.grid,
          },
          {
            title: "Order Dispatch",
            path: PATH_DASHBOARD.general.dispatch,
            icon: ICONS.grid,
          },
          {
            title: "Order Verification",
            path: PATH_DASHBOARD.general.orderVerificationReport,
          },
          {
            title: "Petpooja Report",
            path: PATH_DASHBOARD.general.petPoojaReport,
          },
          {
            title: "Transit Plan Listing",
            path: PATH_DASHBOARD.general.transitPlan,
            icon: ICONS.grid,
          },
          {
            title: "QC Listing",
            path: PATH_DASHBOARD.general.qcListing,
            icon: ICONS.grid,
          },
          {
            title: "Weekly Report",
            path: PATH_DASHBOARD.general.weeklyReport,
          },
        ],
      },
      {
        title: "Order Listing",
        path: PATH_DASHBOARD.general.orderListing,
        icon: ICONS.analytics,
      },

      // {
      //   title: "auth tokens",
      //   path: PATH_DASHBOARD.general.authtokens,
      //   icon: ICONS.authtokens,
      // },

      {
        title: "Ticketing",
        path: PATH_DASHBOARD.general.issues,
        icon: ICONS.alerts,
      },
      {
        title: "Feedback",
        path: PATH_DASHBOARD.general.feedback,
        icon: ICONS.user,
      },
      {
        title: "Shop",
        path: PATH_DASHBOARD.general.shop,
        icon: ICONS.cart,
      },
      {
        title: "Washing Facility",

        children: [
          {
            title: "Collect Containers",
            path: PATH_DASHBOARD.general.collectContainers,
          },
          {
            title: "Zolo Receive Batch",
            path: PATH_DASHBOARD.general.zoloBatch,
          },
          {
            title: "Mark Available",
            path: PATH_DASHBOARD.general.markAvailable,
          },
        ],
      },
      {
        title: "Batch Dispatch & Collection",
        path: PATH_DASHBOARD.general.b2bBatch,
      },
      {
        title: "Batch Listing",
        path: PATH_DASHBOARD.general.batchListing,
      },
      {
        title: "RFID App Requests",
        path: PATH_DASHBOARD.general.rfidBatch,
      },
      {
        title: "Mamaketo",

        children: [
          {
            title: "Dispatch",
            path: PATH_DASHBOARD.general.mamaketoDispatch,
          },
          {
            title: "Collection",
            path: PATH_DASHBOARD.general.mamaketoCollection,
          },
          {
            title: "History",
            path: PATH_DASHBOARD.general.mamaketoHistory,
          },
        ],
      },
    ],
  },
];

export { adminMenu };
