import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

const validKey = process.env.REACT_APP_FCM_KEY;

export const getReqToken = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: validKey });

    if (currentToken) {
      // console.log("token found", currentToken);
      setTokenFound(true);
      return currentToken;
    } else {
      console.log("token not found");
      setTokenFound(false);
      return null;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token.", err);
    throw err;
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onBackgroundMessage((payload) => {
      resolve(payload);
    });
  });
