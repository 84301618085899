import { gql } from "@apollo/client";

export const GET_CUSTOM_ATTRIBUTE_METADATA = gql`
  query CustomAttributeMetadata {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "material_type", entity_type: "4" }
        { attribute_code: "city", entity_type: "4" }
        { attribute_code: "brand_name", entity_type: "4" }
        { attribute_code: "vendor_name", entity_type: "4" }
        { attribute_code: "quality", entity_type: "4" }
        { attribute_code: "vendor_contact", entity_type: "4" }
      ]
    ) {
      items {
        attribute_code
        entity_type
        attribute_options {
          label
          value
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query Products(
    $brand_name: [String!]
    $material_type: [String!]
    $searchTerm: String
    $pageSize: Int!
    $name: String
  ) {
    products(
      filter: {
        brand_name: { in: $brand_name }
        material_type: { in: $material_type }
        name: { match: $name }
      }
      search: $searchTerm
      pageSize: $pageSize
    ) {
      total_count
      items {
        image {
          label
          url
        }
        brand_name
        material_type
        name
        city
        vendor_price
        sku
        vendor_name
        vendor_contact
        quality
      }
    }
  }
`;
