import { adminMenu } from "./AdminMenu";
import { B2BMenu } from "./B2BMenu";
import { dispatchMenu } from "./DispatchMenu";
import { qcMenu } from "./QCMenu";
import { facilityMenu } from "./FacilityMenu";
import { loadShareMenu } from "./LoadShareMenu";
import { platformOwnerMenu } from "./PlatformOwnerMenu";
import { restaurantOwnerMenu } from "./RestaurantOwnerMenu";
import { webMasterMenu } from "./WebmasterMenu";

const menuMapping = {
  1: {
    menuType: webMasterMenu,
  },
  2: {
    menuType: adminMenu,
  },
  3: {
    menuType: adminMenu,
  },
  4: {
    menuType: adminMenu,
  },
  5: {
    menuType: B2BMenu,
  },
  6: {
    menuType: adminMenu,
  },
  7: {
    menuType: restaurantOwnerMenu,
  },
  8: {
    menuType: platformOwnerMenu,
  },
  9: {
    menuType: loadShareMenu,
  },
  12: {
    menuType: facilityMenu,
  },
  13: {
    menuType: dispatchMenu,
  },
  16: {
    menuType: qcMenu,
  },
};

function getMenu(menuType) {
  return menuMapping[menuType];
}

export { getMenu };
