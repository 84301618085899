import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const dispatchMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;
  {
    subheader: "general",
    items: [
      {
        title: "Transit Plan",
        icon: ICONS.booking,
        children: [
          {
            title: "Transit Plan Listing",
            path: PATH_DASHBOARD.general.transitPlan,
            icon: ICONS.grid,
          },
          {
            title: "Create/Edit Plan",
            icon: ICONS.eodReport,
            path: PATH_DASHBOARD.general.createForm,
          },
        ],
      },
      {
        title: "QC",
        icon: ICONS.alerts,
        children: [
          {
            title: "QC Listing",
            path: PATH_DASHBOARD.general.qcListing,
            icon: ICONS.grid,
          },
          {
            title: "QC Form",
            path: PATH_DASHBOARD.general.qcForm,
            icon: ICONS.grid,
          },
        ],
      },
      {
        title: "Driver Report",
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.general.driverReport,

      },
      {
        title: "Plan Master",
        icon: ICONS.booking,
        path: PATH_DASHBOARD.general.planMaster,
      },
    ],
  },
];

export { dispatchMenu };

