import React, { useState } from "react";
import {
  Paper,
  Container,
  CardHeader,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Scrollbar from "src/components/Scrollbar";

function CollectionTable({ date }) {
  // const [page, setPage] = useState(1);

  // const [rowsPerPage, setRowsPerPage] = useState(25);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );
  return (
    <div>
      {!isLoading && (
        <Paper sx={{ margin: "20px", padding: "15px" }}>
          <CardHeader title="Order wise Collection Details" sx={{ mb: 3 }} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl.no</TableCell>
                    <TableCell>Order No.</TableCell>
                    <TableCell>Collection Agent</TableCell>
                    {/* <TableCell>Order Status</TableCell> */}
                    <TableCell>Collection Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Scheduled Slot</TableCell>
                    <TableCell>Collected Slot</TableCell>
                    <TableCell>Collected Time</TableCell>
                    <TableCell>Ticket Name</TableCell>
                    <TableCell>Total Containers</TableCell>
                    <TableCell>Collected</TableCell>
                    <TableCell>Missing</TableCell>
                    <TableCell>Extra Collection</TableCell>
                    <TableCell>Total Collected</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Customer Phone</TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  {data.length !== 0 &&
                    data.orderDetails.map((collection, index) => (
                      <TableRow>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{collection.order_no}</TableCell>
                        <TableCell>{collection.collection_agent}</TableCell>
                        {/* <TableCell>{collection.order_status}</TableCell> */}
                        <TableCell>{collection.collection_status}</TableCell>
                        <TableCell>{collection.customer_name}</TableCell>
                        <TableCell>{collection.scheduled_slot}</TableCell>
                        <TableCell>{collection.collected_slot}</TableCell>
                        <TableCell>{collection.collected_at}</TableCell>
                        <TableCell>{collection.ticket_name}</TableCell>
                        <TableCell>{collection.total_containers}</TableCell>
                        <TableCell>{collection.collected}</TableCell>
                        <TableCell>{collection.missing}</TableCell>
                        <TableCell>{collection.extra_container}</TableCell>
                        <TableCell>{collection.total_collected}</TableCell>
                        <TableCell>{collection.order_date}</TableCell>
                        <TableCell>{collection.customer_phone}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>
      )}

      {!isLoading && (
        <Paper sx={{ margin: "25px", padding: "15px" }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 720 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>
                      {format(new Date(date), "dd-MM-yyyy")}
                    </TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Total Scheduled</TableCell>
                    <TableCell>{data.summary.totalScheduled}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Scheduled Collected</TableCell>
                    <TableCell>{data.summary.totalCollected}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Total Rescheduled </TableCell>
                    <TableCell>{data.summary.totalRescheduled}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total in scheduled state</TableCell>
                    <TableCell>{data.summary.totalInSchedule}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total missed</TableCell>
                    <TableCell>{data.summary.missed}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Paper>
      )}
    </div>
  );
}

export default CollectionTable;
