import { Octokit } from "@octokit/rest";
import { GET_PRODUCTS } from "../graphlqlSchema/schema.js";
import { client } from "../index.js";
import axios from "../utils/axios";

const {
  axiosInstance,
  axiosInstanceV2,
  axiosInstanceInventory,
  axiosInstanceReporting,
  axiosInstanceStageV2,
  axiosInstanceStageV3,
} = axios;

export const getRecentOrders = async (id) => {
  try {
    const sortField = "id";
    const sortOrder = "desc";
    let response = await axiosInstance.post(`/orders/recent-orders`, {
      sortField,
      sortOrder,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling recent-orders api", error);
  }
};
export const getSyncGoogleSheet = async () => {
  try {
    let response = await axiosInstance.get(`/report/sync-google-sheet`);
    return response.data;
  } catch (error) {
    console.log("Error while calling sync-google-sheet api", error);
  }
};

export const postCsvUploadResponse = async (formData) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/batch/read-container-file`,
      formData
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling read-container-file api", error);
  }
};

export const saveProvisionCollectContainerRFID = async (containersData) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/saveProvisionCollectContainerRFID`,
      { container_types: containersData }
    );
    return response.data;
  } catch (error) {
    console.log(
      "Error while calling saveProvisionCollectContainerRFID api",
      error
    );
  }
};

export const getAnalysisReportsPlatform = async (id) => {
  try {
    let response = await axiosInstance.post(`/reports/analysis-report`, {
      from_date: "2022-06-10",
      to_date: "2022-07-12",
      restaurant: id,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling analysis-report api", error);
  }
};
export const getAnalysisReports = async (fromDate, toDate, resid) => {
  try {
    console.log(resid);
    let response = await axiosInstance.post(`/reports/analysis-report`, {
      from_date: fromDate,
      to_date: toDate,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling analysis-report api", error);
  }
};
export const getAdoption = async (fromDate, toDate, resid, view) => {
  try {
    let response = await axiosInstance.post(`/report/weekly-adoption`, {
      from_date: fromDate,
      to_date: toDate,
      group: view,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling weekly-adoption api", error);
  }
};

export const getPlatformList = async () => {
  try {
    let response = await axiosInstanceStageV2.post(`/platform/getPlatformList`);
    return response.data;
  } catch (error) {
    console.log("Error while calling getPlatformList api", error);
  }
};

export const getRestaurantsList = async (platformId) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/restaurants/get-restaurants-by-platform `,
      { platformId }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getRestaurantsList api", error);
  }
};

export const createBatch = async ({
  platform_id,
  restaurant_location_id,
  restaurant_id,
  quantity,
  container_types,
}) => {
  try {
    let response = await axiosInstanceStageV2.post(`/batch/create-batch-rfid`, {
      platform_id,
      restaurant_location_id,
      restaurant_id,
      quantity,
      container_types,
    });
    return response.data;
  } catch (error) {
    console.log("Error while create-batch-rfid api", error);
  }
};

export const getCollection = async (fromDate, toDate, resid, view) => {
  try {
    console.log(resid);

    let response = await axiosInstance.post(`/report/weekly-collection`, {
      from_date: fromDate,
      to_date: toDate,
      group: view,
      restaurant: resid,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling weekly-collection api", error);
  }
};

export const getAllOrders = async (pages) => {
  try {
    let allresponse = await axiosInstance.get(`/orders/all-orders/${pages}`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling all-orders api", error);
  }
};
export const orderVerfication = async (pages) => {
  try {
    let allresponse = await axiosInstance.post(`/reports/order-verification`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling order-verification api", error);
  }
};
export const weeklyReport = async (week) => {
  try {
    console.log("hey");
    let allresponse = await axiosInstance.post(`/reports/weekly-report`);
    return allresponse.data;
  } catch (error) {
    console.log("Error while calling weekly-report api", error);
  }
};
export const collectionReport = async (agent, dateValue) => {
  console.log("hello");
  try {
    let data = {
      filter: {
        agentname: "",
        scheduled_at: "",
      },
      pageNumber: 1,
      pageSize: 100,
    };
    data.filter.agentname = agent;
    data.filter.scheduled_at = dateValue;
    console.log(data);
    let collectionresponse = await axiosInstance.post(
      `/collection-report/agent-report`,
      data
    );
    return collectionresponse.data;
  } catch (error) {
    console.log("Error while calling collectionReport api", error);
  }
};

export const collectionAgentType = async () => {
  try {
    let response = await axiosInstance.get(`/agents/all-agents`);
    return response.data;
  } catch (error) {
    console.log("Error while calling all-agents api", error);
  }
};

export const totalOrders = async (id) => {
  try {
    let response = await axiosInstance.get(`/orders/total-orders`);
    return response.data;
  } catch (error) {
    console.log("Error while calling total-orders api", error);
  }
};

export const restaurantOverview = async () => {
  try {
    let response = await axiosInstanceInventory.get("/restaurant-overview");
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const currentRestaurantData = async (currRestaurant) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/restaurant-details?rest_id=${currRestaurant}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant-details API");
  }
};
export const allAgents = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/all-agents`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const getRestaurantContainer = async (download) => {
  try {
    let response = await axiosInstance.get(
      `inventory/restaurantInventoryCount`,
      {
        params: {
          download: download,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const getInventoryPrediction = async (resto, toDate, time) => {
  try {
    let response = await axiosInstanceV2.post(
      `/api-schedule-process/invertory`,
      {
        orders: [
          {
            restaurant_name: resto,
            date: [`${toDate}`],
          },
        ],
        type: time,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching inventory prediction API");
  }
};

export const downloadPredReport = async (resto, fromDate, toDate, time) => {
  try {
    let response = await axiosInstanceV2
      .post(`/api-schedule-process/invertory_csv`, {
        orders: [
          {
            restaurant_name: resto,
            dates: [`${fromDate}`, `${toDate}`],
            type: time,
          },
        ],
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${fromDate}to${toDate}InventoryPrediction_${time}.csv`
        );
        document.body.appendChild(link);
        link.click();
      });
    return true;
  } catch (error) {
    console.log("Error while downloading inventory prediction API");
  }
};

export const getDayWiseRestaurant = async (day) => {
  try {
    let response = await axiosInstance.post(
      `inventory/getDaywiseRestaurantData`,
      {
        day,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const escalationAgentForAlerts = async (alertID) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/get-escalation-agent-for-alerts?id=${alertID}`
    );
    return response.data.agents ? response.data.agents : [];
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const minQuantity = async (restID, skuType) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/min-quantity?rest_id=${restID}&sku_type=${skuType}`
    );
    return response.data.min_quantity;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const reorderPoint = async ({ reorderPoint, restID, skuType }) => {
  try {
    const response = await axiosInstanceInventory.post("/user", {
      restID,
      skuType,
      reorderPoint,
    });
    return response.data.min_quantity;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const eodType = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/eod-type`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const stakeholderType = async (ledgerType) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/eod-stakeholder-type?id=${ledgerType}`
    );
    return response.data.filter;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};
export const getEodData = async ({
  ledgerType,
  stakeholder,
  selectedDate,
  name,
}) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/eod-data?stakeholder_type=${ledgerType}&stakeholder_name=${name}&stakeholder_filter=${stakeholder}&date=${selectedDate
        .utc()
        .format()}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching restaurant Overview get API");
  }
};

export const getWeeklyReportCard = async (
  fromDate,
  toDate,
  Filter,
  filterName
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    let response = await axiosInstance.post(
      `/collection-report/weeklyReportCardDetails`,
      {
        fromDate: fromDate,
        toDate: toDate,
        filter: filter,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching weekly report card details");
  }
};

export const getWeeklyReport = async (
  fromDate,
  toDate,
  pageNumber,
  pageSize,
  Filter,
  filterName
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }

    let response = await axiosInstance.post(`/collection-report/weeklyReport`, {
      fromDate: fromDate,
      toDate: toDate,
      currentPage: pageNumber,
      rowsPerPage: pageSize,
      filter: filter,
    });
    return response.data;
  } catch (error) {
    console.log("Error while fetching weekly report");
  }
};

export const getLedgerType = async () => {
  try {
    let response = await axiosInstanceInventory.get(`/ledger-type`);
    return response.data;
  } catch (error) {
    console.log("Error while fetching ledger Types");
  }
};
export const getLedgerData = async ({
  ledgerType,
  stakeholder,
  fromDate,
  toDate,
}) => {
  try {
    let response = await axiosInstanceInventory.get(
      `/ledger-data?stakeholder_type=${ledgerType}&stakeholder_id=${stakeholder}&from_date=${fromDate}&to_date=${toDate}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while fetching Ledger Data");
  }
};

export const allContainer = async () => {
  try {
    let t_response = await axiosInstance.get(`/inventory/sku`);
    return t_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const minContainer = async () => {
  try {
    let m_response = await axiosInstance.get(`/inventory/mincount`);
    return m_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const totalSentContainers = async () => {
  try {
    let s_response = await axiosInstance.get(`/inventory/sentcontainer`);
    return s_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const totalReceivedContainers = async (id) => {
  try {
    let r_response = await axiosInstance.get(`/inventory/received`);
    return r_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const batchHistory = async () => {
  try {
    let b_response = await axiosInstance.post(`/batch-history/batches`);
    return b_response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const batchDownload = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.get(`batch-history/batchHistory`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling batch download api", error);
  }
};

export const getTotalSku = async () => {
  try {
    const response = await axiosInstanceReporting.get(`/sku`);
    console.log({ response: response.data });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const containerSummary = async (skuType, summaryContainer) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/summary_data?id=${skuType}&opType=${summaryContainer}`
    );
    return response.data[0];
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const containerSummaryDetail = async (contID, skuType) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/summary_container?opType=${contID}&id=${skuType}`
    );
    return response.data[0];
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const GetMetrics = async (id, skuType) => {
  try {
    const response = await axiosInstanceReporting.get(
      `/metrics?id=${skuType}&opType=${id}`
    );
    return response.data.value;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const GetConsolidatedMetrics = async () => {
  try {
    const response = await axiosInstanceReporting.get(`/consolidated_metrics`);
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const GetGridata = async (
  Filter,
  filterName,
  pageNumber,
  pageSize,
  sortField,
  sortOrder,
  date,
  page
) => {
  let endpoint = "";
  switch (page) {
    case "order_dispatch":
      endpoint = "report/order-dispatch";
      break;
    case "collection_sheet":
      endpoint = "report/container-collection-list";
      break;
    case "container_count":
      endpoint = "report/restaurant-wise-container-count-list";
      break;
    case "weeklyReport":
      endpoint = `/reports/weekly-report`;
      break;
    case "orderVerification":
      endpoint = `/reports/order-verification`;
      break;
    case "orderListing":
      endpoint = `/orders/recent-orders`;
      break;

    default:
      break;
  }
  let dateFilter = "";
  switch (page) {
    case "order_dispatch":
      dateFilter = "created_at";
      break;
    case "collection_sheet":
      dateFilter = "Container_collection_date";
      break;
    case "container_count":
      dateFilter = "updated_at";
      break;
    case "orderVerification":
      dateFilter = "date";
      break;
    case "orderListing": {
      dateFilter = "scheduled_date";
      break;
    }

    default:
      dateFilter = "created_at";
      break;
  }
  try {
    if (filterName.length !== 0) {
      const filter = {};
      filter[Filter] = filterName;
      filter[dateFilter] = date;
      console.log({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        filter: filter,
      });
      const response = await axiosInstance.post(endpoint, {
        filter,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      });
      return response.data;
    } else {
      const filter = {};
      filter[dateFilter] = date;
      console.log({
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: sortField,
        sortOrder: sortOrder,
        filter: filter,
      });
      const response = await axiosInstance.post(endpoint, {
        filter,
        pageNumber,
        pageSize,
        sortField,
        sortOrder,
      });
      return response.data;
    }
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

export const getChartDailyData = async (month, year) => {
  try {
    let response = await axiosInstance.get(
      `/orders/chartDaywiseData/${month}/${year}`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const getChartData = async () => {
  try {
    let response = await axiosInstance.get(`/orders/chartMonthlyData`);
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};

//mamaketo
export const postDispatchValidation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`csv/csvDispatchValidate`, {
      data: file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postCollectionValidation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `csv/csvCollectionValidate`,
      { file }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postDispatchCreation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`b2b/b2bDispatchContainer`, {
      file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postCollectionCreation = async (file) => {
  try {
    let response = await axiosInstanceStageV2.post(`b2b/b2bCollectContainer`, {
      file,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getallpost api", error);
  }
};
export const postAccessToken = async (data) => {
  try {
    console.log("data:", data.username);
    let username = data.username;
    let password = data.password;
    let response = await axiosInstanceStageV2.post(`oauth/access_token`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    console.log("Error while fetching access token", error);
  }
};

// Issues
export const getIssues = async (direction, sort, state, labels) => {
  const octokit = new Octokit({
    auth: "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",
  });
  let total_response = [];
  let i = 1;
  let response_length = 10;
  while (response_length === 10) {
    let res = await octokit.request(
      "GET /repos/ShreyasGeetha/GitIssueLearning/issues",
      {
        owner: "ShreyasGeetha",
        repo: "GitIssueLearning",
        direction: direction,
        sort: sort,
        state: state,
        labels: labels,
        per_page: 10,
        page: i,
      }
    );
    response_length = res.data.length;
    i += 1;
    total_response.push(...res.data);
  }
  console.log(total_response);

  return total_response;
};

//       console.log(total_response);

//       return total_response;
// }

// Container Types

export const addContainerType = async (ContainerName, ContainerStatus) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
    status: ContainerStatus,
  };

  try {
    let response = await axiosInstance.post(
      `containerTypes/addContainerType`,
      ContainerTypeJson
    );
    return response.data;
  } catch (error) {
    console.log("Error while add Container Type", error);
  }
};

export const getContainerTypes = async () => {
  try {
    let response = await axiosInstance.get(`containerTypes/getContainerTypes`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const updateContainerType = async (ContainerName, ContainerStatus) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
    status: ContainerStatus,
  };

  try {
    let response = await axiosInstance.put(
      `containerTypes/updateContainerType`,
      ContainerTypeJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const deleteContainerType = async ({ ContainerName }) => {
  const ContainerTypeJson = {
    container_type: ContainerName,
  };

  try {
    console.log(ContainerTypeJson);
    let response = await axiosInstance.put(
      `containerTypes/deleteContainerType`,
      ContainerTypeJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while deleting Container Types", error);
  }
};

// Containers

export const addContainers = async (containerCount, containerTypeID) => {
  const containersJson = {
    containerCount: containerCount,
    containerTypeID: containerTypeID,
  };

  console.log(containersJson);

  try {
    let response = await axiosInstance.post(
      `containers/create-container-bulk`,
      containersJson
    );
    return response.data;
  } catch (error) {
    console.log("Error while add Container Type", error);
  }
};

export const getContainers = async () => {
  try {
    let response = await axiosInstance.get(`containers/getcontainers`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Container Types", error);
  }
};

export const getBatchListing = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`batch`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Batch Listing", error);
  }
};

export const getBatchDetails = async (id) => {
  try {
    let response = await axiosInstanceStageV2.get(`batch/${id}`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while getting Batch Listing", error);
  }
};

export const getProvisionalContainers = async (id) => {
  try {
    let response = await axiosInstanceStageV2.get(
      `batch/provisional-containers/${id}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionalContainers = async (containers, batchId) => {
  const submitBodyJson = {
    batch_id: batchId,
    containers: containers,
  };
  console.log(submitBodyJson);
  try {
    let response = await axiosInstanceStageV2.post(
      `batch/provisional-add-container-by-system`,
      submitBodyJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyContainers = async (containers, batchId) => {
  const submitBodyJson = {
    batch_id: batchId,
    containers: containers,
  };
  console.log(submitBodyJson);
  try {
    let response = await axiosInstanceStageV2.post(
      `batch/validate-batch-containers`,
      submitBodyJson
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionCollectContainer = async (container, agent) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/addProvisionCollectContainerSystem`,
      {
        container: container,
        collection_agent: agent,
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addProvisionAvailableContainer = async (container) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/addProvisionCollectContainerSystem`,
      {
        container: container,
        collection_agent: "app-admin",
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const refreshContainers = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/refreshRedisContainers`
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const finalizeCollectContainers = async (agent) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/saveProvisionCollectContainerSystem`,
      {
        collection_agent: agent,
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const markContainerAvailable = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/collection-agent/markContainerAvailable`,
      {
        collection_agent: "app-admin",
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getContainerStatus = async () => {
  try {
    let response = await axiosInstanceStageV2.get(
      `/containers/byStatusCount/In Washing Center`
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const receiveBatch = async (
  fromDate,
  toDate,
  exportType,
  isb2b,
  page,
  rowsPerPage
) => {
  try {
    let response = await axiosInstance.post(`/report/receive-batch`, {
      filter: {
        collected_at: `${fromDate}:${toDate}`,
        exportType: exportType,
        is_for_btob: isb2b,
      },

      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: "id",
      sortOrder: "desc",
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFeedback = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/feedback/getFeedbackDump`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get feedback api", error);
  }
};

export const getTicketType = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/ticket/getTicketType`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get ticket type api", error);
  }
};

export const getAllTicketDetails = async (
  fromDate,
  toDate,
  agent,
  ticket,
  orderNo
) => {
  try {
    let response = await axiosInstanceStageV2.get(
      `/ticket/getAllTicketDetails`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          collection_agent: agent,
          ticket_type: ticket,
          order_no: orderNo,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling get all ticket api", error);
  }
};

export const getTicketDetails = async (order_id, ticket_id) => {
  try {
    let response = await axiosInstanceStageV2.post(`/ticket/getTicketDetails`, {
      order_id: order_id,
      ticket_id: ticket_id,
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling get ticket details api", error);
  }
};

export const getOrderInfo = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.get(
      `/restaurants/get-restaurant-order-information/`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling get order info api", error);
  }
};

export const getOrderComments = async (order_id) => {
  try {
    let response = await axiosInstanceStageV2.get(`/orders/${order_id}`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get order comments api", error);
  }
};

export const getB2BStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/report/daily-consumes-container-stats`,
      {
        filter: {},
        pageNumber: 1,
        pageSize: 10,
        sortField: "id",
        sortOrder: "asc",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};

export const getB2BRestStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/inventory/getDailyConsumesContainerStatDetails`,
      {
        platform_id: 1,
        stats_date: "2022-09-20",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};

export const updateB2BStats = async () => {
  try {
    let response = await axiosInstance.post(
      `/inventory/addUpdateDailyConsumesContainerStats`,
      {
        platform_id: 1,
        stats_date: "2022-09-20",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b stats api", error);
  }
};

export const getRestaurantNames = async () => {
  try {
    let response = await axiosInstance.get(`/restaurants/getRestaurants`);
    return response.data;
  } catch (error) {
    console.log("Error while calling get resto info api", error);
  }
};

export const b2bContainerCount = async (fromDate, toDate, restaurant) => {
  try {
    let b_response = await axiosInstance.get(
      `/batch-history/b2bContainersCount`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
          restaurantName: restaurant,
        },
      }
    );
    return b_response.data;
  } catch (error) {
    console.log("Error while calling b2b container count api", error);
  }
};

export const rfidRequest = async (creation_at, page, rowsPerPage) => {
  try {
    let b_response = await axiosInstance.post(`/report/rfid-requests`, {
      filter: {
        creation_at,
      },
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: "id",
      sortOrder: "desc",
    });
    return b_response.data;
  } catch (error) {
    console.log("Error while calling rfidRequest api", error);
  }
};

export const optedForBagCustomerOrders = async (
  Filter,
  filterName,
  creation_at,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    filter[Filter] = filterName;
    filter["creation_at"] = creation_at;
    filter["exportType"] = exportType;
    let b_response = await axiosInstance.post(
      `report/opted-for-bag-customer-orders`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: "id",
        sortOrder: "desc",
      }
    );
    return b_response.data;
  } catch (error) {
    console.log("Error while calling optedForBagCustomerOrders api", error);
  }
};

export const getDispatchPlanListing = async (
  Filter,
  filterName,
  creation_at,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    filter[Filter] = filterName;
    filter["creation_at"] = creation_at;
    filter["exportType"] = exportType;
    let b_response = await axiosInstance.post(`report/dispatch-plan-listing`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: "id",
      sortOrder: "desc",
    });
    return b_response.data;
  } catch (error) {
    console.log("Error while calling getDispatchPlanListing api", error);
  }
};

export const getRestaurantStatusHistoryListing = async (
  Filter,
  filterName,
  creation_at,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }

    filter[Filter] = filterName;
    filter["creation_at"] = creation_at;
    filter["exportType"] = exportType;
    let b_response = await axiosInstance.post(
      `report/restaurant-status-history`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: "id",
        sortOrder: "desc",
      }
    );
    return b_response.data;
  } catch (error) {
    console.log(
      "Error while calling getRestaurantStatusHistoryListing api",
      error
    );
  }
};

export const getTransitPlanListing = async (
  Filter,
  filterName,
  transit_date,
  page,
  rowsPerPage,
  sort,
  sortField,
  exportType
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }

    filter["transit_date"] = transit_date;
    filter["exportType"] = exportType;

    let b_response = await axiosInstanceStageV2.post(
      `/transit-plan/get-transit-plan-listing`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: sortField,
        sortOrder: sort,
      }
    );
    return b_response.data;
  } catch (error) {
    console.log("Error while calling transit listing api", error);
  }
};

export const getPlanMasterListing = async (
  Filter,
  filterName,
  plan_date,
  page,
  rowsPerPage,
  sort,
  sortField
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }

    filter["plan_date"] = plan_date;

    let b_response = await axiosInstanceStageV2.post(`/plan/get-plan-listing`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: sortField,
      sortOrder: sort,
    });
    return b_response.data;
  } catch (error) {
    console.log("Error while calling plan master listing api", error);
  }
};

export const deleteTransit = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/delete-transit-plan`,
      {
        transit_plan_id: data,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while deleting Transit ", error);
  }
};

export const createQC = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/processComplaints`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling createQC api", error);
  }
};

export const getQClisting = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/listComplaints`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling createQC api", error);
  }
};

export const createUpdatePlan = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/plan/create-update-plan`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling createUpdatePlan master api", error);
  }
};

export const deleteMaster = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(`/plan/delete-plan`, {
      plan_id: data,
    });
    return response.data;
  } catch (error) {
    console.log("Error while deleting Transit ", error);
  }
};

export const clonePlan = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/plan/reschedule-plan`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling clonePlan api", error);
  }
};

export const cloneTransitPlan = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/reschedule-transit-plan`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling clonePlan api", error);
  }
};

export const getB2BBatches = async (
  Filter,
  filterName,
  creation_at,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }

    filter["creation_at"] = creation_at;
    filter["exportType"] = exportType;

    let response = await axiosInstance.post(`/batch-history/batch-listing`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: "id",
      sortOrder: "desc",
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b batch listing api", error);
  }
};

export const optedForBagCallDetails = async (order_id) => {
  try {
    let b_response = await axiosInstance.post(
      `report/opted-for-bag-call-details`,
      {
        order_id,
      }
    );
    return b_response.data;
  } catch (error) {
    console.log("Error while calling optedForBagCallDetails api", error);
  }
};

export const getOrderReport = async (date) => {
  try {
    let response = await axiosInstance.get(`orders/notCreatedOrders`, {
      params: {
        searchDate: date,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling getOrderReport api", error);
  }
};

export const getDriverReport = async (
  Filter,
  filterName,
  fromDate,
  toDate,
  page,
  rowsPerPage,
  sort,
  sortField,
  exportType
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    filter["exportType"] = exportType;
    filter["fromDate"] = fromDate;
    filter["toDate"] = toDate;
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/listCheckInOut`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: sortField,
        sortOrder: sort,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling get Driver Report api", error);
  }
};

export const getCities = async () => {
  try {
    let response = await axiosInstanceStageV2.post(`/transit-plan/get-cities`);
    return response.data;
  } catch (error) {
    console.log("Error while calling cities api", error);
  }
};

export const getCountries = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/geo/country`);
    return response.data;
  } catch (error) {
    console.log("Error while calling countries api", error);
  }
};

export const getAllCities = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/geo/cities`);
    return response.data;
  } catch (error) {
    console.log("Error while getting cities", error);
  }
};

export const getStates = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/geo/state`);
    return response.data;
  } catch (error) {
    console.log("Error while calling states api", error);
  }
};

export const getLocationType = async () => {
  try {
    let response = await axiosInstanceStageV2.get(`/geo/locationType`);
    return response.data;
  } catch (error) {
    console.log("Error while calling location types api", error);
  }
};

export const getChecks = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-container-conditions`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling condition checks api", error);
  }
};

export const getTypes = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-transit-types`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling condition checks api", error);
  }
};

export const getVehicleTypes = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-vehicles`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling condition checks api", error);
  }
};

export const getPlanContainers = async (id) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-dispatch-container-types`,
      {
        transitId: id,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getPlanContainers api", error);
  }
};

export const getB2BContainers = async (id) => {
  try {
    let response = await axiosInstance.post(
      `/batch-history/batch-container-details`,
      {
        batchId: id,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getB2BContainers api", error);
  }
};

export const getPlanDetails = async (id) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-transit-plan-details`,
      {
        transitId: id,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling getPlanDetails api", error);
  }
};

export const createTransit = async (createNew) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/create-transit-plan`,
      createNew
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling createTransit api", error);
  }
};

export const getProducts = async (values, pageSize) => {
  try {
    const searchText = `${values.name} ${values.sku}`;
    const brandArray = values.brand === "" ? [] : values.brand;
    const categoryArray = values.category === "" ? [] : values.category;
    let nameFilter = "";
    if (values.checkboxName) {
      nameFilter = values.name;
    }
    const response = await client.query({
      query: GET_PRODUCTS,
      variables: {
        brand_name: brandArray,
        material_type: categoryArray,
        searchTerm: searchText,
        pageSize: pageSize,
        name: nameFilter,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error while calling createTransit api", error);
  }
};

export const addUpdateRestaurant = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/restaurants/createUpdateRestaurant`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while adding and updating restaurant api", error);
  }
};

export const addUpdateUser = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/users/createUpdateUser`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while adding and updating user api", error);
  }
};

export const addUpdateUserType = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/users/createUpdateUserTypes`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while adding or updating user type", error);
  }
};

export const editTransit = async (editPlan) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/update-transit-plan`,
      editPlan
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling editTransit api", error);
  }
};

export const initiateTransit = async (initiateNew) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/initiate-transit-plan`,
      initiateNew
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling initiate new transit api", error);
  }
};

export const completeTransit = async (completed) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/complete-transit-plan`,
      completed
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling initiate new transit api", error);
  }
};

export const uploadImage = async (imageData) => {
  try {
    let response = await axiosInstanceStageV3.post(
      `/image/uploadImage`,
      imageData
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling upload image api", error);
  }
};

export const getDispatchRestaurants = async (cityId) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/transit-plan/get-citywise-restaurants`,
      {
        cityId: cityId,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling restaurant in cities api", error);
  }
};

export const getComplaints = async () => {
  try {
    let response = await axiosInstanceStageV2.get(
      `/transit-plan/getComplaintTypes`
    );
    return response.data;
  } catch (error) {
    console.log("Error while getting complain types api", error);
  }
};

export const getSKUs = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/batch/get-container-types-with-actions`
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling sku types api", error);
  }
};

export const getB2BCard = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.post(
      `/batch-history/batch-inventory-card`,
      {
        fromDate: fromDate,
        toDate: toDate,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b card api", error);
  }
};

export const getB2BYearlyCard = async (year) => {
  try {
    let response = await axiosInstance.post(
      `/batch-history/batch-sent-containers-yearly`,
      {
        year: year,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b yearly card api", error);
  }
};

export const getB2BMonthlyCard = async (year, month) => {
  try {
    let response = await axiosInstance.post(
      `/batch-history/batch-sent-containers-monthly`,
      {
        year: year,
        month: month,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling b2b monthly card api", error);
  }
};

export const getPetPoojaReport = async (
  fromDate,
  toDate,
  Filter,
  filterName,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    filter[Filter] = filterName;
    filter["creation_at"] = `${fromDate}:${toDate}`;
    filter["exportType"] = exportType;

    let response = await axiosInstance.post(
      `/report/vendor-order-log-listing`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: "id",
        sortOrder: "desc",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling petpooja report api", error);
  }
};

export const getPackagingReport = async (
  fromDate,
  toDate,
  Filter,
  filterName,
  page,
  rowsPerPage,
  exportType
) => {
  try {
    const filter = {};
    filter[Filter] = filterName;
    filter["creation_at"] = `${fromDate}:${toDate}`;
    filter["exportType"] = exportType;
    let response = await axiosInstance.post(
      `/report/packaging-charges-listing`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: "id",
        sortOrder: "desc",
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling packaging report api", error);
  }
};

export const collAgentScanReport = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.post(
      `/collection-report/collectionAgentContainerReport`,
      {
        fromDate: fromDate,
        toDate: toDate,
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling collAgentScanReport api", error);
  }
};

export const getZoloBatch = async (fromDate, toDate) => {
  try {
    let response = await axiosInstance.get(
      `/collection-report/getZoloReceiveBatch`,
      {
        params: {
          fromDate: fromDate,
          toDate: toDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error while calling zolo batch api", error);
  }
};

export const getOptinOrderList = async (
  fromDate,
  toDate,
  Filter,
  filterName,
  exportType,
  page,
  rowsPerPage
) => {
  try {
    const filter = {};
    filter[Filter] = filterName;
    filter["optin_created_date"] = `${fromDate}:${toDate}`;
    filter["exportType"] = exportType;
    let response = await axiosInstance.post(`/report/optin-report`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: "id",
      sortOrder: "desc",
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRestaurant = async (
  Filter,
  filterName,
  page,
  rowsPerPage,
  sort,
  sortField
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    let response = await axiosInstanceStageV2.post(
      `/restaurants/getRestaurant`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: sortField,
        sortOrder: sort,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPlatform = async (
  Filter,
  filterName,
  page,
  rowsPerPage,
  sort,
  sortField
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    let response = await axiosInstanceStageV2.post(
      `/platform/getPlatformList`,
      {
        filter: filter,
        pageNumber: page,
        pageSize: rowsPerPage,
        sortField: sortField,
        sortOrder: sort,
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserTypes = async (
  Filter,
  filterName,
  page,
  rowsPerPage,
  sort,
  sortField,
  show
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    let response = await axiosInstanceStageV2.post(`/users/getUserTypes`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: sortField,
      sortOrder: sort,
      showAll: show,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = async (
  Filter,
  filterName,
  page,
  rowsPerPage,
  sort,
  sortField
) => {
  try {
    const filter = {};
    if (filterName !== "") {
      filter[Filter] = filterName;
    }
    let response = await axiosInstanceStageV2.post(`/users/getUsers`, {
      filter: filter,
      pageNumber: page,
      pageSize: rowsPerPage,
      sortField: sortField,
      sortOrder: sort,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addUpdatePlatform = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/platform/createUpdatePlatform`,
      data
    );
    console.log(data);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("Error while adding or updating user type", error);
  }
};
export const deletePlatform = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/platform/createUpdatePlatform`,
      data
    );
    return response.data;
  } catch (error) {
    console.log("Error while deleting PlatformName", error);
  }
};

export const getAllNotifications = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/notification/getAdminNotifications`,
      {
        filter: {},
        pageNumber: 1,
        pageSize: 10,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const markReadNotifications = async (messageId) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/notification/readAdminNotification`,
      {
        messageId: messageId,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMamaketoUsers = async (date) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/mamaKeto/getCurrentUsers`,
      {
        date: date,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const insertMamaketoData = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/mamaKeto/insertContainerData`,
      {
        data: data,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const collectMamaketoContainers = async (data) => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/mamaKeto/updateCollectedContainers`,
      {
        containers: data,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMamaketoHistory = async () => {
  try {
    let response = await axiosInstanceStageV2.post(
      `/mamaKeto/historyContainers`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
