import { Suspense, lazy, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// layouts
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import DashboardLayout from "../layouts/dashboard";
import MainLayout from "../layouts/main";
// guards
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {
  PATH_AFTER_DISPATCH,
  PATH_AFTER_LOGIN,
  PATH_AFTER_QC,
} from "../config";
// components
import OrderListing from "src/pages/dashboard/OrderListing";
import LoadingScreen from "../components/LoadingScreen";

import CollectionAgentReport from "src/pages/dashboard/CollectionAgentReport/CollectionAgentReport";
import IssueDetails from "src/pages/dashboard/IssueDetails";
import Issues from "src/pages/dashboard/Issues";
import OrderVerificationReport from "src/pages/dashboard/OrderVerificationReport";
import WeeklyReport from "src/pages/dashboard/WeeklyReport";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [menu, setMenu] = useState(localStorage.getItem("menu"));
  const user = localStorage.getItem("userTypeId");

  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <Navigate
              to={
                user === "12" || user === "13"
                  ? PATH_AFTER_DISPATCH
                  : user === "16"
                  ? PATH_AFTER_QC
                  : PATH_AFTER_LOGIN
              }
              replace
            />
          ),
          index: true,
        },
        { path: "dashboard", element: <DashboardTypes /> },
        { path: "alerts", element: <Alerts /> },
        { path: "dispatch", element: <Dispatch /> },
        { path: "collectionSheet", element: <CollectionSheet /> },
        { path: "continerCountList", element: <ContinerCountList /> },
        { path: "eodReport", element: <EodReport /> },
        { path: "ledger", element: <Ledger /> },
        { path: "inventory", element: <Inventory /> },
        { path: "app", element: <GeneralApp /> },
        { path: "ecommerce", element: <GeneralEcommerce /> },
        { path: "analytics", element: <GeneralAnalytics /> },
        { path: "banking", element: <GeneralBanking /> },
        { path: "booking", element: <GeneralBooking /> },
        { path: "auth-tokens", element: <AuthTokens /> },
        { path: "order-listing", element: <OrderListing /> },
        { path: "overview", element: <Overview /> },
        { path: "metrics", element: <Metrics /> },
        { path: "history", element: <History /> },
        { path: "insights", element: <Insights /> },
        { path: "order-verification", element: <OrderVerificationReport /> },
        { path: "weekly-report", element: <WeeklyReport /> },
        { path: "issues", element: <Issues /> },
        { path: "zoloBatch", element: <ZoloBatch /> },
        { path: "issues/:issueID", element: <IssueDetails /> },
        { path: "transit/:transitID", element: <TransitView /> },
        // { path: "containers", element: <Containers /> },
        // { path: "containerTypes", element: <ContainerTypes /> },
        { path: "collectionAgent-report", element: <CollectionAgentReport /> },
        { path: "petpooja-report", element: <PetPoojaReport /> },
        { path: "packaging-report", element: <PackagingReport /> },
        { path: "optedForBag", element: <OptedForBag /> },
        { path: "dispatchPlan", element: <DispatchPlan /> },
        { path: "transit-plan", element: <TransitPlan /> },
        { path: "qc-form", element: <QCForm /> },
        { path: "driver-report", element: <DriverReport /> },
        { path: "manage-restaurants", element: <ManageRestaurant /> },
        { path: "manage-restaurants/add", element: <AddRestaurant /> },
        { path: "manage-restaurants/edit", element: <AddRestaurant /> },
        { path: "manage-platforms", element: <ManagePlatform /> },
        { path: "manage-platforms/add", element: <AddPlatform /> },
        { path: "manage-platforms/edit", element: <AddPlatform /> },
        { path: "manage-user-types", element: <ManageUserTypes /> },
        { path: "manage-user-types/add", element: <AddUserType /> },
        { path: "manage-user-types/edit", element: <AddUserType /> },
        { path: "manage-users", element: <ManageUsers /> },
        { path: "manage-users/add", element: <AddUser /> },
        { path: "manage-users/edit", element: <AddUser /> },

        { path: "qc-listing", element: <QCListing /> },
        {
          path: "restaurantStatusHistory",
          element: <RestaurantStatusHistory />,
        },
        { path: "mamaketo", element: <MamaKeto /> },
        { path: "shop", element: <ShopProducts /> },
        { path: "mamaketo-dispatch", element: <MamaketoTable /> },
        { path: "mamaketo-collection", element: <MamaketoCollect /> },
        { path: "mamaketo-history", element: <MamaketoHistory /> },
        { path: "feedback", element: <Feedback /> },

        {
          path: "batchListing",
          element: <BatchListing />,
        },
        {
          path: "b2b-batch-listing",
          element: <B2BBatchListing />,
        },
        {
          path: "rfidBatch",
          element: <RFIDBatch />,
        },
        {
          path: "batchListing/:batchId",
          element: <CreateBatch />,
        },
        { path: "collectContainers", element: <ReceiveBatch /> },
        { path: "collectContainers/new", element: <CollectContainers /> },
        { path: "optin-orders", element: <OptinOrderList /> },
        { path: "create-form", element: <MainForm /> },
        { path: "plan-master", element: <PlanMaster /> },
        { path: "plan-master/create", element: <CreatePlanMaster /> },
        { path: "plan-master/clone", element: <ClonePlanMaster /> },
        { path: "transit-plan/clone", element: <ClonePlanMaster /> },
        // { path: "collectContainers", element: <CollectContainers /> },
        { path: "markAvailable", element: <MarkAvailable /> },

        { path: "notScheduled", element: <NotScheduled /> },

        { path: "b2bBatch", element: <B2BBatch /> },
        { path: "b2bBatch/:batchID", element: <B2BBatchContainers /> },
        {
          path: "e-commerce",
          children: [
            {
              element: <Navigate to="/dashboard/e-commerce/shop" replace />,
              index: true,
            },
            { path: "shop", element: <EcommerceShop /> },
            { path: "product/:name", element: <EcommerceProductDetails /> },
            { path: "list", element: <EcommerceProductList /> },
            { path: "product/new", element: <EcommerceProductCreate /> },
            { path: "product/:name/edit", element: <EcommerceProductCreate /> },
            { path: "checkout", element: <EcommerceCheckout /> },
          ],
        },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "invoice",
          children: [
            {
              element: <Navigate to="/dashboard/invoice/list" replace />,
              index: true,
            },
            { path: "list", element: <InvoiceList /> },
            { path: ":id", element: <InvoiceDetails /> },
            { path: ":id/edit", element: <InvoiceEdit /> },
            { path: "new", element: <InvoiceCreate /> },
          ],
        },
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            { path: "posts", element: <BlogPosts /> },
            { path: "post/:title", element: <BlogPost /> },
            { path: "new", element: <BlogNewPost /> },
          ],
        },
        {
          path: "mail",
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: "label/:customLabel", element: <Mail /> },
            { path: "label/:customLabel/:mailId", element: <Mail /> },
            { path: ":systemLabel", element: <Mail /> },
            { path: ":systemLabel/:mailId", element: <Mail /> },
          ],
        },
        {
          path: "chat",
          children: [
            { element: <Chat />, index: true },
            { path: "new", element: <Chat /> },
            { path: ":conversationKey", element: <Chat /> },
          ],
        },
        { path: "calendar", element: <Calendar /> },
        { path: "kanban", element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "pricing", element: <Pricing /> },
        { path: "payment", element: <Payment /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: "about-us", element: <About /> },
        { path: "contact-us", element: <Contact /> },
        { path: "faqs", element: <Faqs /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/ResetPassword"))
);
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

// DASHBOARD
const DashboardTypes = Loadable(
  lazy(() => import("../pages/dashboard/DashboardTypes"))
);
const Inventory = Loadable(lazy(() => import("../pages/dashboard/Inventory")));
const Alerts = Loadable(lazy(() => import("../pages/Inventory/Alerts")));
const Dispatch = Loadable(
  lazy(() => import("../pages/dashboard/OrderDispatch"))
);
const CollectionSheet = Loadable(
  lazy(() => import("../pages/dashboard/collectionSheet"))
);
const ContinerCountList = Loadable(
  lazy(() => import("../pages/dashboard/ContinerCountList"))
);
const EodReport = Loadable(lazy(() => import("../pages/Inventory/EodReports")));
const Ledger = Loadable(lazy(() => import("../pages/Inventory/Ledger")));
const Overview = Loadable(lazy(() => import("../pages/Reporting/Overview")));
const Metrics = Loadable(lazy(() => import("../pages/Reporting/Consolidated")));
const History = Loadable(
  lazy(() => import("../pages/Reporting/ContainerHistory"))
);
const Insights = Loadable(
  lazy(() => import("../pages/Reporting/ContainerInsights"))
);
const MamaKeto = Loadable(lazy(() => import("../pages/dashboard/MamaKeto")));
const ShopProducts = Loadable(lazy(() => import("../pages/dashboard/Shop")));

const MamaketoTable = Loadable(
  lazy(() => import("../pages/dashboard/MamaketoTable"))
);
const MamaketoCollect = Loadable(
  lazy(() => import("../pages/dashboard/MamaketoCollect"))
);

const MamaketoHistory = Loadable(
  lazy(() => import("../pages/dashboard/MamaketoHistory"))
);

const Feedback = Loadable(lazy(() => import("../pages/dashboard/Feedback")));

const CreateBatch = Loadable(
  lazy(() => import("../pages/dashboard/CreateBatch"))
);

const ManageRestaurant = Loadable(
  lazy(() => import("../pages/dashboard/ManageRestaurant"))
);

const ManageUserTypes = Loadable(
  lazy(() => import("../pages/dashboard/ManageUserTypes"))
);

const ManageUsers = Loadable(
  lazy(() => import("../pages/dashboard/ManageUsers"))
);

const AddRestaurant = Loadable(
  lazy(() => import("../pages/dashboard/AddRestaurant"))
);

const ManagePlatform = Loadable(
  lazy(() => import("../pages/dashboard/ManagePlatform"))
);

const AddPlatform = Loadable(
  lazy(() => import("../pages/dashboard/AddPlatform"))
);

const AddUser = Loadable(lazy(() => import("../pages/dashboard/AddUser")));

const AddUserType = Loadable(
  lazy(() => import("../pages/dashboard/AddUserType"))
);

const BatchListing = Loadable(
  lazy(() => import("../pages/dashboard/BatchListing"))
);

const B2BBatchListing = Loadable(
  lazy(() => import("../pages/dashboard/B2BBatchListing"))
);

const OptedForBag = Loadable(
  lazy(() => import("../pages/dashboard/OptedForBag"))
);

const DispatchPlan = Loadable(
  lazy(() => import("../pages/dashboard/DispatchPlan"))
);

const QCForm = Loadable(lazy(() => import("../pages/dashboard/QCForm")));

const QCListing = Loadable(lazy(() => import("../pages/dashboard/QcListing")));

const TransitPlan = Loadable(
  lazy(() => import("../pages/dashboard/TransitPlan"))
);

const DriverReport = Loadable(
  lazy(() => import("../pages/dashboard/DriverReport"))
);

const PlanMaster = Loadable(
  lazy(() => import("../pages/dashboard/PlanMaster"))
);

const CreatePlanMaster = Loadable(
  lazy(() => import("../pages/dashboard/CreatePlanMaster"))
);

const ClonePlanMaster = Loadable(
  lazy(() => import("../pages/dashboard/ClonePlanMaster"))
);

const TransitView = Loadable(
  lazy(() => import("../pages/dashboard/TransitView"))
);

const RestaurantStatusHistory = Loadable(
  lazy(() => import("../pages/dashboard/RestaurantStatusHistory"))
);
const RFIDBatch = Loadable(lazy(() => import("../pages/dashboard/RFIDBatch")));

const ReceiveBatch = Loadable(
  lazy(() => import("../pages/dashboard/ReceiveBatch"))
);

const CollectContainers = Loadable(
  lazy(() => import("../pages/dashboard/CollectContainers"))
);

const ZoloBatch = Loadable(lazy(() => import("../pages/dashboard/ZoloBatch")));

const PetPoojaReport = Loadable(
  lazy(() => import("../pages/dashboard/PetPoojaReport"))
);

const PackagingReport = Loadable(
  lazy(() => import("../pages/dashboard/PackagingReport"))
);

const MarkAvailable = Loadable(
  lazy(() => import("../pages/dashboard/MarkAvailable"))
);

const NotScheduled = Loadable(
  lazy(() => import("../pages/dashboard/NotScheduled"))
);

const MainForm = Loadable(lazy(() => import("../pages/dashboard/MainForm")));

const B2BBatch = Loadable(lazy(() => import("../pages/dashboard/B2BBatch")));

const B2BBatchContainers = Loadable(
  lazy(() => import("../pages/dashboard/B2BBatchContainers"))
);

const OptinOrderList = Loadable(
  lazy(() => import("../pages/dashboard/OptinOrderList"))
);
// const HelloWorld = Loadable(
//   lazy(() => import("../pages/dashboard/HelloWorld"))
// );
// AUTH TOKENS

const AuthTokens = Loadable(
  lazy(() => import("../pages/dashboard/AuthTokens"))
);

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const GeneralEcommerce = Loadable(
  lazy(() => import("../pages/dashboard/GeneralEcommerce"))
);
const GeneralAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/GeneralAnalytics"))
);
const GeneralBanking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBanking"))
);
const GeneralBooking = Loadable(
  lazy(() => import("../pages/dashboard/GeneralBooking"))
);

// ECOMMERCE
const EcommerceShop = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceShop"))
);
const EcommerceProductDetails = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductDetails"))
);
const EcommerceProductList = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductList"))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceProductCreate"))
);
const EcommerceCheckout = Loadable(
  lazy(() => import("../pages/dashboard/EcommerceCheckout"))
);

// INVOICE
const InvoiceList = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceList"))
);
const InvoiceDetails = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceDetails"))
);
const InvoiceCreate = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceCreate"))
);
const InvoiceEdit = Loadable(
  lazy(() => import("../pages/dashboard/InvoiceEdit"))
);

// BLOG
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")));
const BlogNewPost = Loadable(
  lazy(() => import("../pages/dashboard/BlogNewPost"))
);

// USER
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);

// APP
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")));
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")));
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")));
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")));

// MAIN
const HomePage = Loadable(lazy(() => import("../pages/Home")));
const About = Loadable(lazy(() => import("../pages/About")));
const Contact = Loadable(lazy(() => import("../pages/Contact")));
const Faqs = Loadable(lazy(() => import("../pages/Faqs")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Pricing = Loadable(lazy(() => import("../pages/Pricing")));
const Payment = Loadable(lazy(() => import("../pages/Payment")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
