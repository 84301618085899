import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  activeStep: 0,
};

const slice = createSlice({
  name: "transit",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onBackStep(state) {
      state.activeStep -= 1;
    },

    onNextStep(state) {
      state.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.activeStep = goToStep;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onGotoStep, onBackStep, onNextStep } = slice.actions;

// ----------------------------------------------------------------------
